import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

const resources = {
  en: {
    translation: {
      "menu": "Menu",
      "rings": "Rings",
      "wedding_dress": "Wedding Dress",
      "flowers": "Flowers",
      "cakes": "Cakes",
      "gifts": "Gifts",
      "hotel": "Hotel",
      "honeymoon": "Honeymoon Travel",
      "latest_news": "Latest News",
    }
  },
  cn: {
    translation: {
      "menu": "菜单",
      "rings": "戒指",
      "wedding_dress": "婚纱",
      "flowers": "花束",
      "cakes": "蛋糕",
      "gifts": "礼物",
      "hotel": "酒店",
      "honeymoon": "蜜月旅行",
      "latest_news": "最新信息",
    }
  },
  jp: {
    translation: {
      "menu": "メニュー",
      "rings": "指輪",
      "wedding_dress": "ウェディングドレス",
      "flowers": "花束",
      "cakes": "ケーキ",
      "gifts": "ギフト",
      "hotel": "ホテル",
      "honeymoon": "新婚旅行",
      "latest_news": "最新情報",
    }
  },
  my: {
    translation: {
      "menu": "Menu",
      "rings": "Cincin",
      "wedding_dress": "Gaun Pengantin",
      "flowers": "Bunga",
      "cakes": "Kek",
      "gifts": "Hadiah",
      "hotel": "Hotel",
      "honeymoon": "Bulan Madu",
      "latest_news": "Berita Terkini",
    }
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en", // Default language
  fallbackLng: "en", // Fallback language
  interpolation: {
    escapeValue: false // React already escapes by default
  }
});

export default i18n;
