import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginModal from '../components/LoginModal';  // Updated path

function QuestionnairePage() {
  const [selectedTab, setSelectedTab] = useState('Muslim Marriage');
  const [isLoggedIn, setIsLoggedIn] = useState(false);  // Simulate login state
  const [showModal, setShowModal] = useState(false);    // State to show/hide modal
  const navigate = useNavigate();

  const marriageTypes = [
    'Muslim Marriage',
    'Chinese Marriage',
    'Hindu Marriage',
    'Christian Marriage',
    'Buddhist Marriage',
    'Sikh Marriage',
    'Interfaith Marriage'
  ];

  const questionnaireOptions = {
    'Muslim Marriage': [
      'Discussion between families',
      'Attend marriage course',
      'Nikah ceremony',
      'Wedding reception'
    ],
    // Add other options for different marriage types
  };

  const handleTabClick = (type) => {
    setSelectedTab(type);
  };

  const handleNext = () => {
    if (isLoggedIn) {
      navigate('/wedding-planning');  // Navigate to date and time selection page
    } else {
      setShowModal(true);  // Show the login modal if not logged in
    }
  };

  const handleLogin = () => {
    setIsLoggedIn(true);  // Set login state to true
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="questionnaire-container">
      <div className="tabs">
        <ul>
          {marriageTypes.map((type) => (
            <li key={type} onClick={() => handleTabClick(type)} className={selectedTab === type ? 'active' : ''}>
              {type}
            </li>
          ))}
        </ul>
      </div>

      <div className="content">
        <h3>{selectedTab}</h3>
        <ul>
          {questionnaireOptions[selectedTab].map((option, index) => (
            <li key={index}>
              <input type="checkbox" id={`option-${index}`} />
              <label htmlFor={`option-${index}`}>{option}</label>
            </li>
          ))}
        </ul>

        <button onClick={handleNext}>
          {isLoggedIn ? 'Next' : 'Load'}
        </button>
      </div>

      {/* Render the login modal if showModal is true */}
      {showModal && <LoginModal onClose={closeModal} onLogin={handleLogin} />}
    </div>
  );
}

export default QuestionnairePage;
