import React from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18n from '../config/i18n'; // Adjust the path

function MainPage() {
  const { t } = useTranslation(); // Hook for translations
  const navigate = useNavigate();  // Use navigate to move between pages

  // Language switching function
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div>
      {/* Language Selector */}
      <div className="language-selector">
        <button onClick={() => changeLanguage('en')}>English</button>
        <button onClick={() => changeLanguage('cn')}>中文</button>
        <button onClick={() => changeLanguage('jp')}>日本語</button>
        <button onClick={() => changeLanguage('my')}>Bahasa Melayu</button>
      </div>

      {/* Main Menu */}
      <div className="menu">
        <h3>{t('menu')}</h3>
        <ul>
          <li>{t('rings')}</li>
          <li>{t('wedding_dress')}</li>
          <li>{t('flowers')}</li>
          <li>{t('cakes')}</li>
          <li>{t('gifts')}</li>
          <li>{t('hotel')}</li>
          <li>{t('honeymoon')}</li>
        </ul>
      </div>

      {/* Photo Gallery Section */}
      <div className="photo-gallery">
        <h2>WedPro</h2>
        <div className="photos">
          <img src="photo1.jpg" alt="Photo 1" />
          <img src="photo2.jpg" alt="Photo 2" />
          {/* Add more images as needed */}
        </div>
      </div>

      {/* Plan a Wedding Button */}
      <button onClick={() => navigate('/questionnaire')}>
        Plan a Wedding
      </button>

      {/* Latest Information Section */}
      <div className="latest-news">
        <h2>{t('latest_news')}</h2>
        <p>Here you can display the latest news or information</p>
      </div>
    </div>
  );
}

export default MainPage;
