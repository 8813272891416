import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import MainPage from './pages/MainPage';  // Existing main page
import QuestionnairePage from './pages/QuestionnairePage';
import WeddingPlanningPage from './pages/WeddingPlanningPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<MainPage />} />
        <Route path="/questionnaire" element={<QuestionnairePage />} />
        <Route path="/wedding-planning" element={<WeddingPlanningPage />} />
      </Routes>
    </Router>
  );
}

export default App;
