import React, { useState } from 'react';

function WeddingPlanningPage() {
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedTime, setSelectedTime] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();
    alert(`You have selected ${selectedDate} and ${selectedTime} for your wedding plan.`);
  };

  return (
    <div className="select-date-container">
      <h3>Select Date and Time for Wedding Plan</h3>

      <form onSubmit={handleSubmit}>
        <label>
          Select Date:
          <input type="date" value={selectedDate} onChange={(e) => setSelectedDate(e.target.value)} required />
        </label>

        <label>
          Select Time:
          <input type="time" value={selectedTime} onChange={(e) => setSelectedTime(e.target.value)} required />
        </label>

        <button type="submit">Submit</button>
      </form>
    </div>
  );
}

export default WeddingPlanningPage;
